<template>
    <div class="px-3 py-2"> 
        <v-form ref="forinv" lazy-validation>
            <b-sidebar id="add_check" aria-labelledby="sidebar-no-header-title" no-header backdrop width="80%" :style="`direction:`+lang.ldir" right :title="lang.add_check" shadow >
                <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:140px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                            <span>{{lang.add_check}}</span>
                        </div>
                        <div @click="hide" id="HideCheckup" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                            <span>{{lang.close}}</span>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="px-3 py-2" style="direction:rtl">
                        <v-row>
                            <v-col cols="12" md="3" sm="6" xs="12">
                                <label>{{ lang.checkup_type }}</label>
                                <b-input-group>
                                    <b-form-select
                                        class="selborder"
                                        v-model="checkup.checkup_type"
                                        :options="checkUpTypes"
                                        @change="InvType()"
                                        style="background:green !important"
                                    ></b-form-select>
                                </b-input-group>
                            </v-col>
                            <v-col cols="12" md="2" sm="6" xs="12">
                                <label>{{ lang.mobile }}</label>
                                <b-form-input
                                    class="inborder"
                                    v-model="checkup.mobile"
                                    @change="getCustomer()"
                                ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="2" sm="6" xs="12">
                                <label>{{ lang.customer_name }}</label>
                                <b-form-input
                                    class="inborder"
                                    v-model="checkup.customer_name"
                                    readonly
                                ></b-form-input>
                            </v-col>
                        </v-row>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default {
    data() {
        return {
            checkup: {
                checkup_type: 1,
                mobile: '',
                customer_name: '',
                customer_id: 0,
            },
            checkTypes: [
                { value: 1, name_ar: 'شركات تأمين', name_en: 'Insurance Co'},
                { value: 2, name_ar: 'فخص عام', name_en: 'General Check'},
                { value: 3, name_ar: 'عقود صيانة', name_en: 'Contracts'},
            ],
            showInsurance: true,
            showGeneral: false,
            showContract: false
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        checkUpTypes: function(){
            let t = [];
            for(let i=0;i<this.checkTypes.length;i++){
                t.push({value: this.checkTypes[i].value, text: this.checkTypes[i][`name_${this.lang.langname}`]})
            }
            return t;
        }
    },
    methods: {
        InvType() {
            if(this.checkup.checkup_type == 1){
                this.showContract = false;
                this.showGeneral = false;
                this.showInsurance = true;
            }
            else if(this.checkup.checkup_type == 2){
                this.showContract = false;
                this.showGeneral = true;
                this.showInsurance = false;
            }
            else if(this.checkup.checkup_type == 3){
                this.showContract = true;
                this.showGeneral = false;
                this.showInsurance = false;
            }
        },
        reset(){
            this.checkup = {
                checkup_type: 1,
                mobile: '',
                customer_name: '',
                customer_id: 0,
            }
        },
        getCustomer()
        {
            if(this.checkup.mobile == '') return false;
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','customerInfoAll');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[mobile]',this.checkup.mobile);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                console.log(response);
                if(response.data.results.data.length == 0){
                    let message = "العميل غير موجود يرجى اضافة العميل من قائمة العملاء في قسم المبيعات";
                    this.$snotify.error(message, this.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    return false;
                }
                const customer = response.data.results.data[0]
                if(this.checkup.checkup_type == 1 && customer.customer_type != 2){
                    let message = "العميل المدخل ليس شركة";
                    this.$snotify.error(message, this.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    return false;
                }
                this.checkup.customer_id = customer.id
                if(customer.customer_type == 2){
                    this.checkup.customer_name = customer.company_name
                }
                else if(customer.customer_type == 1){
                    this.checkup.customer_name = customer.full_name
                }
                else if(customer.customer_type == 3){
                    this.checkup.customer_name = customer.entity_name
                }
            })
        }
    }
}
</script>
